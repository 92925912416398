<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row" v-if="!isDistributor">
      <div class="col-md-12">
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="distributor"
          v-model:provinsi="provinsi"
        ></filter-distributor>
        <a-date-picker
          v-model:value="start_date"
          placeholder="Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          :format="formatDated"
          :disabled-date="disabledStartDate"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          v-if="isDistributor || isDistributor_khusus"
          v-model:value="start_date"
          placeholder="Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          :format="formatDated"
          :disabled-date="disabledStartDate"
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="m-2"
          title="Download Excel Detail Stok Distributor"
          type="primary"
          @click="fetchXlsx"
          :loading="download"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!download"></i>
          <span v-else>Download ...</span>
        </a-button>
        <a-button
          class="m-2"
          title="Download Excel"
          type="primary"
          @click="fetchXls"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Download ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive">
      <md-table
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        ><template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #ribuan="{ text }">
          <span>{{ formatRibuan(text) }} </span>
        </template>
        <template #levelStok="{ text }">
          <span v-if="parseInt(text) >= 100" class="text-custom-red">
            {{ text ? Math.round(text) + '%' : 0 }}
          </span>
          <span v-else-if="parseInt(text) < 100 && parseInt(text) >= 80" class="text-custom-orange">
            {{ text ? Math.round(text) + '%' : 0 }}
          </span>
          <span v-else class="text-custom-dark">
            {{ text ? Math.round(text) + '%' : 0 }}
          </span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Detail Stok Distributor">
              <a href="javascript: void(0);" class="btn btn-sm btn-success" @click="detail(record)">
                <i class="fe fe-info fa-lg" />&nbsp;Detail
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <a-modal
      v-model:visible="modalVisible"
      title="Detail Stok Distributor"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modalVisible = false"
    >
      <template #footer>
        <a-button key="back" type="primary" @click="modalVisible = false">Close</a-button>
      </template>
      <div class="table-responsive">
        <md-table
          :columns="detailcolumns"
          :data-source="detaildata"
          size="small"
          row-key="id"
          :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          bordered
          :loading="isFetching"
        >
        </md-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  nextTick,
  onMounted,
  toRefs,
  onBeforeMount,
  provide,
  watch,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterDistributor from '@/components/filter/FilterDistributorOI'
import qs from 'qs'
import { useDisabledDate } from '@/composables/DisabledDate'
import useUserInfo from '@/composables/useUserInfo'
import moment from 'moment'
import store from '@/store'

const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Distributor SI',
    dataIndex: 'vendor_si',
  },
  {
    title: 'Kode Distributor SBI',
    dataIndex: 'vendor_sbi',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor',
  },
  {
    title: 'Kode Gudang SI',
    dataIndex: 'gudang_si',
  },
  {
    title: 'Kode Gudang SBI',
    dataIndex: 'gudang_sbi',
  },
  //  {
  //   title: 'Kode Distrik',
  //   dataIndex: 'kode_distrik',
  // },
  // {
  //   title: 'Nama Distrik',
  //   dataIndex: 'nama_distrik',
  // },
  {
    title: 'Tanggal Stok',
    dataIndex: 'tanggal',
  },
  {
    title: 'Nama Gudang',
    dataIndex: 'gudang',
  },
  {
    title: 'Alamat Gudang',
    dataIndex: 'address',
  },
  {
    title: 'Kapasitas Gudang',
    dataIndex: 'kapasitas',
  },

  {
    title: 'Kemampuan Bongkar Gudang',
    dataIndex: 'kapasitas_bongkar',
  },
  {
    title: 'Volume Stok (ZAK)',
    dataIndex: 'stok',
  },
  {
    title: 'Level Stok',
    dataIndex: 'level_stock',
    slots: { customRender: 'levelStok' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

const bcolumns = [
  {
    title: 'Kode Gudang SI',
    dataIndex: 'gudang_si',
  },
  {
    title: 'Kode Gudang SBI',
    dataIndex: 'gudang_sbi',
  },
  {
    title: 'Tanggal Stok',
    dataIndex: 'tanggal',
  },
  {
    title: 'Nama Gudang',
    dataIndex: 'gudang',
  },
  {
    title: 'Nama Brand',
    dataIndex: 'brand',
  },
  {
    title: 'Kode Produk',
    dataIndex: 'kode_produk',
  },
  {
    title: 'Nama Produk',
    dataIndex: 'nama_produk',
  },
  {
    title: 'Volume Stok (ZAK)',
    dataIndex: 'stok',
  },
]

export default defineComponent({
  name: 'MFilter',
  components: {
    FilterProvince,
    FilterDistributor,
  },
  setup() {
    const columns = ref([])
    const detailcolumns = ref([])
    const data = ref([])
    const detaildata = ref([])
    const modalVisible = ref(false)

    columns.value = acolumns
    detailcolumns.value = bcolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')

    const provinsi = ref([])
    const distributor = ref([])
    // const start_date = ref()
    const errorMessage = ref(null)
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
    }

    const {
      start_date,
      disabledStartDate,
      firstDayMonth,
      lastDayMonth,
      startDate,
    } = useDisabledDate()

    start_date.value = moment(new Date()).subtract(1, 'days')

    const formatDated = 'DD MMMM YYYY'

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        provinsi: provinsi.value,
        distributor: distributor.value,
        date: startDate.value
          ? startDate.value
          : moment(new Date())
              .add(-1, 'days')
              .format(formatDated),
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report/distributor', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXls = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/distributor', {
          params: {
            ...params.value,
            _export2: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-stok-distributor_${moment().format('YYYY-MM-DD_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const fetchXlsx = () => {
      state.download = true
      apiClient
        .get('/api/report/distributor', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-detail-stok-distributor_${moment().format('YYYY-MM-DD_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.download = false
        })
    }
    const fetchDetail = record => {
      const _params = {
        gudang: record.gudang_param,
        distributor: record.vendor_param,
        date: startDate.value
          ? startDate.value
          : moment(new Date())
              .add(-1, 'days')
              .format(formatDated),
      }
      state.isFetching = true
      apiClient
        .get('api/report/detail-distributor', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          detaildata.value = items
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const detail = record => {
      modalVisible.value = true
      fetchDetail(record)
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
      download: false,
    })

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }
    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
    })
    const search = () => {
      fetchData()
    }

    return {
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      data,
      detaildata,
      columns,
      detailcolumns,
      q,
      searchText,
      searchInput,
      handleReset,
      handleSearch,
      current1,
      handleTableChange,
      startRow,
      onSelectChange,
      state,
      fetchXlsx,
      fetchXls,
      provinsi,
      distributor,
      fetchData,
      fetchDetail,
      ...toRefs(state),
      start_date,
      search,
      disabledStartDate,
      firstDayMonth,
      lastDayMonth,
      errorMessage,
      formatDated,
      modalVisible,
      detail,
      moment,
      isDistributor,
      isDistributor_khusus,
    }
  },
})
</script>

<style>
.text-custom-red {
  color: #ff0000;
}

.text-custom-orange {
  color: #ffa500;
}

.text-custom-yellow {
  color: #eedf11;
}

.text-custom-dark {
  color: #000000;
}

.text-custom-green {
  color: #008000;
}
</style>
